<template>
  <v-container>
    <v-row>
      <v-col>
        <go-back-btn :routeToGo="routeToGo" />
        <PageHeader :title="`${title} ${this.nomCliente}`" class="mb-3" />
        <!-- Data table -->
        <v-card>
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="itemsAccesoDatos"
            :loading="loading"
            item-key="idDatos"
            no-data-text="No hay datos disponibles para mostrar"
            :search="searchConsulta"
          >
            <template v-slot:[`item.datosTexto`]="{ item }">
              <tr
                v-for="(line, index) in item.datosTexto.split('\n')"
                :key="index"
              >
                <td>{{ line }}</td>
              </tr>
            </template>
            <template v-slot:[`item.habilitado`]="{ item }">
              <v-icon
                v-if="item.habilitado"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10" align-self="center" class="pt-5">
                    <v-text-field
                      v-model="searchConsulta"
                      clearable
                      label="Buscar"
                      single-line
                      :append-icon="iconoSearch"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    align="end"
                    class="pt-5"
                    v-if="canCreateDatosAcceso"
                  >
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEditDatosAcceso">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar datos</span>
              </v-tooltip>
              <v-tooltip left v-if="canDeleteDatosAcceso">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteDatos(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar datos</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalUsers"
      v-model="openModalUsers"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditAccesoDatos
        :idReg="idReg"
        :recibeId="recibeId"
        :itemsAccesoDatos="itemsAccesoDatos"
        :registrosParams="registrosParams"
        :regClientesId="regClientesId"
        @closeAndReload="closeAndReload"
      ></EditAccesoDatos>
    </v-dialog>
    <DeleteDialog
      max-width="30%"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditAccesoDatos from "@/views/EditAccesoDatos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, EditAccesoDatos, DeleteDialog },
  name: "AccesoDatos",
  data() {
    return {
      titleDelete: "¿Eliminar datos de acceso?",
      showDeleteModal: false,
      isFormValid: false,
      loading: false,
      routeToGo: "/clientes",
      checkIcon: enums.icons.CHECK_OUTLINE,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      rules: rules,
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      openModalUsers: false,
      itemsAccesoDatos: [],
      regClientesId: null,
      advancedUsers: false,
      title: "Datos de acceso del cliente: ",
      idToDelete: null,
      recibeId: null,
      registrosParams: {},
      idReg: null,
      nomCliente: "",
      allowedActions: null,
      canCreateDatosAcceso: false,
      canEditDatosAcceso: false,
      canDeleteDatosAcceso: false,
      headers: [
        { text: "Datos de acceso", sortable: false, value: "datosTexto" },
        {
          text: "Activo",
          align: "center",
          sortable: false,
          value: "habilitado",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  async created() {
    this.setPermisos();
    this.regClientesId = this.$route.params.itemsClientes;
    this.nomCliente = this.regClientesId.clienteNombre;
    this.idReg = this.regClientesId.clienteId;
    this.loadDatosAcceso();
  },
  methods: {
    ...mapActions({
      getDatosAcceso: "clientesData/getDatosAcceso",
      deleteAccesoDatos: "clientesData/deleteAccesoDatos",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.modules.adminSistema.permissions.NUEVO_DATO_ACCESO:
            this.canCreateDatosAcceso = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.EDIT_DATOS_ACCESO:
            this.canEditDatosAcceso = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.DELETE_DATOS_ACCESO:
            this.canDeleteDatosAcceso = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalUsers = true;
      this.registrosParams = item;
    },
    closeAndReload() {
      this.openModalUsers = false;
      this.loadDatosAcceso();
    },
    async loadDatosAcceso() {
      this.loading = true;
      const response = await this.getDatosAcceso(this.idReg);
      this.itemsAccesoDatos = response;
      this.loading = false;
    },
    deleteDatos(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.idDatos;
    },
    async confirmDelete() {
      const response = await this.deleteAccesoDatos(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDatosAcceso();
      }
    },
  },
};
</script>
<style></style>
